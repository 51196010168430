import { AddUpdateAwardCategoryRequest, AwardCategoryInfo, AwardsApi, Role, useAuthContext, UserInfo, UsersApi } from '@pull/pull-groupm-csp-api'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { KTCard, KTCardBody } from '../../../../_metronic/helpers'
import { QUERIES } from '../../../Constants/Queries'
import { ErrorBanner} from '../../../helpers/components/generic/NotificationBanner'
import { getErrorFromResponse } from '../../../helpers/functions/getErrorFromResponse'
import { Success } from '../../../helpers/functions/SwalHelpers'
import * as Yup from 'yup'
import { getJudgeTagify } from '../../../helpers/functions/TagifyInstance'
import { useNavigate } from 'react-router-dom'

type Props = {
    awardCat: AwardCategoryInfo
}

const editSettingSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    shortName: Yup.string().required('Acronym is required'),
    sendJudgeNotifications: Yup.boolean(),
   judges: Yup.string().required("Must select at least one")
})

const AwardCategoryDetailSettings: FC<Props> = ({ awardCat }) => {
    const [settings] = useState({ id: awardCat.id, name: awardCat.name, shortName:awardCat.shortName, sendJudgeNotifications:awardCat.sendJudgeNotifications, judges:  JSON.stringify(awardCat.judges?.map((user) => {return {value:user.id, email:user.email, name:user.firstName && user.lastName? `${user.firstName} ${user.lastName}`:""}}))}) //automaticWinnerAnouncement:awardCat.automaticWinnerAnnouncement
    const [error, setError] = useState<string | null>(null)
    const { getApiConfiguration } = useAuthContext()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const formik = useFormik({
        initialValues: settings,
        validationSchema:editSettingSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            const awardApi = new AwardsApi(getApiConfiguration())
            const judgeIds = JSON.parse(values.judges).map((val:any) => {return val.value})
            const updateAwardCat: AddUpdateAwardCategoryRequest = { id: values.id, name: values.name, shortName:values.shortName, sendJudgeNotifications: values.sendJudgeNotifications, judges:judgeIds , automaticWinnerAnnouncement: awardCat.automaticWinnerAnnouncement ?? false };
            try {
                const result = await awardApi.apiAwardsAwardCategoriesPost(updateAwardCat)
                if (result?.id) {
                    queryClient.invalidateQueries({queryKey :[QUERIES.AWARDS.GET_CATEGORY, awardCat.id]})
                    queryClient.invalidateQueries({queryKey :[QUERIES.AWARDS.GET_ALL_CATEGORIES]})
                    Success("The award category settings were successfully updated!")
                    navigate(`/awards/${result?.id}/details`)
                }
            } catch (ex) {
                if (ex as Response) {
                    const errorMessage = await getErrorFromResponse(ex as Response)
                    setError(errorMessage)
                }
                else {
                    console.log(ex)
                }
            }
        }
    })

    useEffect(() =>{
        const judgeInput = document.getElementById("add_judge")
        getJudgeTagify(judgeInput,getApiConfiguration())
    },[])

    return (
        <KTCard className="mb-5 mb-xl-10">
            <div className="card-header border-0">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Settings</h3>
                </div>
            </div>
            <div id="case-study-settings">
                <form id="case-study-settings_form" className="form" onSubmit={formik.handleSubmit} noValidate>
                    <KTCardBody className="border-top p-9">
                        {error &&
                            <ErrorBanner message={error} />
                        }
                        <div className="row mb-7">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6" htmlFor="name">Name</label>
                        <div className="col-lg-8">
                            <input {...formik.getFieldProps("name")}
                                type="text" id="name"
                                className={clsx(
                                    'form-control form-control-solid',
                                    { 'is-invalid': formik.touched.name && formik.errors.name },
                                    {
                                        'is-valid': formik.touched.name && !formik.errors.name,
                                    }
                                )}
                                 />
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6" htmlFor="shortName">Acronymn</label>
                        <div className="col-lg-8">
                            <input {...formik.getFieldProps("shortName")}
                                type="text" id="shortName"
                                className={clsx(
                                    'form-control form-control-solid',
                                    { 'is-invalid': formik.touched.shortName && formik.errors.shortName },
                                    {
                                        'is-valid': formik.touched.shortName && !formik.errors.shortName,
                                    }
                                )} />
                            {formik.touched.shortName && formik.errors.shortName && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.shortName}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6" htmlFor="sendJudgeNotifications">Send email notifications to judges</label>
                        <div className="col-lg-8 d-flex flex-column justify-content-center">
                            <div className="form-check form-switch form-check-custom form-check-solid">
                                <input {...formik.getFieldProps("sendJudgeNotifications")}
                                    checked={formik.values.sendJudgeNotifications}
                                    type="checkbox" id="sendJudgeNotifications"
                                    className="form-check-input h-20px w-30px" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                        Add Judges
                        <br />
                        (judges allowed to score this category)
                        </label>
                        <div className="col-lg-8 d-flex flex-column justify-content-center">
                            {/* resolve using tagify over Typeahead
                             */}

                        <input id="add_judge"
                        //onInput={onJudgeChange}
                        {...formik.getFieldProps("judges")}
                        className={clsx(
                            'form-control form-control-solid',
                            { 'is-invalid': formik.touched.judges && formik.errors.judges },
                            {
                                'is-valid': formik.touched.judges && !formik.errors.judges,
                            }
                        )}
                        />
                        {formik.touched.judges && formik.errors.judges && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.judges}</span>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                        {/* <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Featured</label>
                            <div className="col-lg-8 d-flex align-items-center">
                                <div className="form-check form-check-custom form-check-solid form-switch fv-row">
                                    <input className="form-check-input w-45px h-30px"
                                        {...formik.getFieldProps('automaticWinnerAnnouncment')}
                                        type="checkbox"
                                        id="featured"
                                        checked={formik.values.automaticWinnerAnnouncment}
                                        disabled={formik.isSubmitting} />
                                    <label className="form-check-label" >Automatically announce winner in news article</label>
                                </div>
                            </div>
                        </div> */}
                    </KTCardBody>
                    <div className="card-footer sticky bottom-0 d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-info" id="case-study-settings_submit">Save Changes</button>
                    </div>
                </form>
            </div>
        </KTCard>
    )
}

export default AwardCategoryDetailSettings